import Swiper from 'swiper';

WIDTH = 320

slidesPerView = ->
  width = $('.swiper-container').innerWidth()
  return Math.floor(width / WIDTH)

slidesPerGroup = ->
  width = $('.swiper-container').innerWidth()
  return Math.floor(width / WIDTH)

onReachEnd = ->
  homepageBlockId = this.el.dataset['id']
  return if  homepageBlockId == ""
  offset = this.wrapperEl.querySelectorAll('.swiper-slide').length
  per    = slidesPerGroup()
  Rails.ajax
    type: "GET"
    url: "/api/homepage_blocks/#{homepageBlockId}.json?"
    data: "offset=#{offset}&per=#{per}"
    success: (data) =>
      return if data.length == 0
      this.appendSlide(data)

init = ->
  swiper = new Swiper('.swiper-container', {
    speed: 300
    loop: false
    cssMode: false # don't use, breaks transitions
    slidesPerView: slidesPerView()
    slidesPerGroup: slidesPerGroup()
    centeredSlides: false
    spaceBetween: 10
    preloadImages: true
    updateOnImagesReady: false
    runCallbacksOnInit: false
    pagination:
      el: '.swiper-pagination'
    navigation:
      nextEl: '.swiper-button-next'
      prevEl: '.swiper-button-prev'
    on:
      reachEnd: onReachEnd
  })

export { init }
