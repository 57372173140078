init = ->
  $('.search-form').on("input", onChange)

onChange = (e) ->
  # InputEvent is the event fired when new text added.
  # When the clear (x) button is tapped, the event class is Event. This is the one we want
  unless e.originalEvent instanceof InputEvent
    $('.search-form').submit();

export { init }
