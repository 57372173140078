// This file is  shared across each pack, and should contain the base setup for all JS


let $ = require("jquery")
// Make jQuery available to the server-generated JavaScripts
window.$ = $;

require("turbolinks").start()
require("@rails/activestorage").start()

require("@rails/ujs").start()
require("popper.js")
require("bootstrap")
require("@fortawesome/fontawesome-free/js/all.min.js")

let flashes = require("shared/flashes");
window.flashes = flashes;

let searchForms = require("shared/search_forms");

document.addEventListener("turbolinks:load", function() {
  searchForms.init();
});
