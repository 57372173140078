##

init = ->
  $('form[data-recaptcha="true"] input[type="submit"]').on("click", onEvent)

onEvent = (e) ->
  e.preventDefault()
  grecaptcha.ready =>
    $form = $(this).parents("form")
    key = $("[data-recaptcha-site-key]").data("recaptcha-site-key")
    action = $form.data("action")
    # console.log("key", key)
    # console.log("action", action)
    grecaptcha.execute(key, { action: action }).then (token) ->
      # console.log("token", token)
      # Add your logic to submit to your backend server here.
      hiddenField = document.createElement('input')
      hiddenField.type = "hidden"
      hiddenField.name = "recaptcha-response"
      hiddenField.value = token
      # console.log hiddenField
      $form.prepend(hiddenField)
      $form.trigger('requestSubmit') # request needed to acknowledge renmote: true

export { init }
