
init = ->
  $(document).on("click", ".js-geolocation-button", onClick)

onClick = (e) ->
  e.preventDefault()
  $link  = $(this)
  addressString = collateAddressFromFields($(this).data("fields"))

  $.ajax
    url: $link.attr("href")
    method: "get"
    success: (data) ->
      mapObject = document.querySelector('.map')
      event = new Event('map:marker:change_coords');
      event.details = JSON.parse(data);
      # Dispatch the event.
      mapObject.dispatchEvent(event);

    data:
      address: addressString

collateAddressFromFields = (fieldNamesString) ->
  fieldNames = fieldNamesString.split(",")
  addressFields = fieldNames.map (fieldName) -> $("input[name='#{fieldName}']").val()
  addressFields = $.grep addressFields, (fieldVal) -> return fieldVal != ""
  addressFields.join(",")

export { init }
