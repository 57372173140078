// Generated by CoffeeScript 2.3.2
// Basic JS functionality for one-time-password form. Designed to be used with jQuery and
// Bootstrap.

// Automatically generated by the orthodox gem (https://github.com/katanacode/orthodox)
// (c) Copyright 2019 Katana Code Ltd. All Rights Reserved.

// Initialize method called when jQuery ready
var init, onClick;

init = function() {
  return $("body").on("click", ".js-tfa-link", onClick);
};

// Callback when toggle links are clicked. Shows/hides form fields and links
onClick = function(e) {
  e.preventDefault();
  $(".js-tfa-link").toggleClass("d-none");
  return $(".js-tfa-field-group").toggleClass("d-none");
};

export {
  init
};
