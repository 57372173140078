SELECTOR = "[data-track='visit']"

init = ->
  $(SELECTOR).each (index, visitable) ->
    $visitable = $(visitable)
    unless $visitable.data("visited") == true
      visitableType = $visitable.data("visitable")
      slug = $visitable.data("slug")

      Rails.ajax
        url: "/api/#{visitableType}s/#{slug}/visits.json"
        type: "POST"
        success: ->
          $visitable.data("visited", true)
          console.debug("Visits registered")

export { init }
