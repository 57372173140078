###
This file deals with the responsive, collapsing, main public navbar.

For non-mobile devices, the navbar link items are displayed or hidden depending on
whether they fit within the navbar or not. Anything that doesn't fit is tucked into a
dropdown menu labelled "More".

Each item in the nav has a corresponding link in the dropdown menu. Their relationship is
defined through a data attribute called "data-navbar-link-index".

As the screen is resized, links are hidden or revealed based on the width of the navbar.

For mobile screens, or screens smaller than the defined breakpoint, the hamburger menu is
used.

Gavin Morrice 2020
###

##
# Breakpoint size. Smaller than this and it won't recalculate or collapse
MD_BREAKPOINT = 768 #px

##
# Have we collapsed down to the burger menu
collapsed = window.innerWidth <= MD_BREAKPOINT

# Call this function every time the screen is resized
redraw = ->
  # If the window is smaller than the breakpoint, and we haven't collapsed yet...
  if mobileWidth() && collapsed
    return # Nothing else to do
  else if mobileWidth() && !collapsed
    setForCollapsed()
    collapsed = true
  else
    setForWidth()
    collapsed = false

init = ->
  $(redraw)

setForCollapsed = ->
  $(".dropdown-item[data-navbar-link-index]").hide()
  $(".nav-item[data-navbar-link-index]").show()
  $("#more-dropdown").hide()

##
# Screen is smaller than the defined breakpoint for 'mobile'
mobileWidth = ->
  window.innerWidth <= MD_BREAKPOINT

##
# Set the navbar for the given screen width
setForWidth = () ->
  # Total inner width of the navbar
  totalWidth = $(".main-public-navbar .container-fluid").innerWidth()
  # Width of the brand badge
  brandWidth = $(".navbar-brand").outerWidth()
  # Minimum width of the search form
  minSearchFormWidth = 248 # px
  # More item width
  moreWidth = 66 # px
  # Additional spacing
  additionalSpacing = 55
  # Navbar allowance
  allowance = totalWidth - brandWidth - minSearchFormWidth - moreWidth - additionalSpacing
  # How many pixels have already been used up
  widthUsed = 0

  $moreDropdown = $("#more-dropdown")

  # For each nav-item within the navbar that isn't the "more" item...
  $(".js-main-nav-links .nav-item:not(.dropdown)").each (index, element) ->
    $element  = $(element)
    widthUsed += $element.outerWidth()
    linkIndex = $element.data("navbar-link-index")
    $menuLink = $(".dropdown-item[data-navbar-link-index=#{linkIndex}]")
    # If the nav is too smalll to fit this link, hide it, and show it's corresponding link
    # in the dropdown.
    if widthUsed >= allowance
      $element.hide()
      $menuLink.show()
    else
      $element.show()
      $menuLink.hide()

  # If the screen is wider than the links in the nav, hide the 'more' link. Otherwise,
  # show it.
  if widthUsed <= allowance
    $moreDropdown.hide()
  else
    $moreDropdown.show()

export { redraw, init }
