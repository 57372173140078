##
# Handles the main search form on the homepage.
# One of the notable features is the rotating placeholder on the search field. This
# will move from one prompt to the next in a loop.


# ==============================================================================
# = Constants =
# ==============================================================================

##
# List of prompts to loop through
SEARCH_PROMPTS = [
  "Airport taxi information",
  "Bus services to Fort William",
  "Train information",
  "Outdoor activities",
  "Good cask ale",
  "Long stay parking",
]

##
# Delay between each letter
LETTER_DELAY = 45 # ms

##
# Delay between each prompt
PAUSE_DELAY = 1000 # ms

# ==============================================================================
# = Globall variables =
# ==============================================================================

##
# Number of times to loop the prompts. This variable should be reset on page load
max_rounds = 3

##
# Which prompt in the list are we currently on. Set to -1 so we can increment to zero at
# the start of the loop.
promptIndex = -1

##
# Which letter in the prompt are we currently on
index = 0

##
# Stores the current timer object
timer = null

##
# Initialize the whole thing
init = ->
  # If there's no search form, reset everything and ignore
  return unless document.querySelector(".main-search-form")

  $(".main-search-form #search_term").one "focus keydown click", clearSearch
  $("[name='search[location_present]']").on("change", onLocationPresentChange)
  $(".main-search-form #search_type").on "change", onTypeChange
  $(".main-search-form #search_distance").on "input", onDistanceChange

  # Reset ready for a fresh loop
  resetGlobalVariables()
  # Start loop now
  timer = setTimeout(changePrompt, 0)

##
# When the search type changes, update the prompt on the search field
onTypeChange = (e) ->
  value = $(".main-search-form #search_type option:selected").text().toLowerCase()
  newValue = "Search #{value}"
  $(".main-search-form input[type='search']").prop("placeholder", newValue)

onDistanceChange = (e) ->
  distance = $(this).val()
  plural = distance > 1
  string = if plural then "miles" else "mile"
  $('.main-search-form-distance-label').html("#{distance} #{string}")

onLocationPresentChange = (e) ->
  showDistance = $(this).val() == 'true'
  $('.search-distance-and-location').toggleClass('visible', showDistance)

##
# Change the current animate prompt
changePrompt = ->
  selector = ".main-search-form input[type='search']"
  return if $(selector).val() != ""
  if promptIndex == SEARCH_PROMPTS.length - 1
    max_rounds--
    if max_rounds == 0
      clearSearch()
      return
  else
    promptIndex++
  prompt = SEARCH_PROMPTS[promptIndex]
  charIndex = 0
  animateForwards(selector, prompt, charIndex)

##
# Clear the whole thing out
clearSearch = ->
  resetGlobalVariables()
  randomPlaceholder = SEARCH_PROMPTS[SEARCH_PROMPTS.length * Math.random() | 0]
  $(".main-search-form input[type='search']").attr("placeholder", "e.g. #{randomPlaceholder}")

resetGlobalVariables = ->
  clearTimeout(timer)
  timer = null
  promptIndex = -1
  index = 0
  max_rounds = 3

##
# Animate text forwards
animateForwards = (selector, string, charIndex) ->
  charIndex++
  value = string.substr(0, charIndex)
  $field = $(selector)
  $field.attr("placeholder", value)
  if value == string
    timer = setTimeout(changePrompt, PAUSE_DELAY)
  else
    timer = setTimeout(->
      animateForwards(selector, string, charIndex)
    , LETTER_DELAY)

export { init }
