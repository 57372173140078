API_KEY = null

DEFAULT_LAT = 56.819683
DEFAULT_LON = -5.105320
DEFAULT_ZOOM = 12
DEFAULT_CENTER = { lat: DEFAULT_LAT, lng: DEFAULT_LON }

INTERACTIVE_MAP = null
MAP_MARKERS = []

init = ->
  mapObject = $('.map-interactive')
  if mapObject.length == 0
    INTERACTIVE_MAP = null
    MAP_MARKERS = []
    return
  else
    $('form.filter-form').on 'change', onFilterChange
    API_KEY = mapObject.data("google-api-key")
    loadScript()

initMap = ->
  mapElement = document.getElementsByClassName('map-interactive')[0]
  INTERACTIVE_MAP = new google.maps.Map(mapElement, {
    zoom: DEFAULT_ZOOM,
    center: DEFAULT_CENTER
  })
  fetchLocationsFromAPI()


onFilterChange = (e) ->
  fetchLocationsFromAPI()

fetchLocationsFromAPI = ->
  $form = $('form.filter-form')
  fetchURL = new URL(window.location.origin)
  fetchURL.pathname = $form.attr("action")
  fetchURL.search = new URLSearchParams($form.serialize()).toString()

  fetch fetchURL
  .then (response) -> response.json()
  .then (data) -> updateMapMarkers(data)

updateMapMarkers = (array) ->
  marker.setMap(null) for marker in MAP_MARKERS

  MAP_MARKERS = []
  INFO_WINDOWS = []

  for location in array
    newMarker = new google.maps.Marker
      position: { lat: location.latitude, lng: location.longitude },
      map: INTERACTIVE_MAP,
      title: location.name
      icon: location.iconURL

    newMarker.description = location.description
    newMarker.href = location.href
    newMarker.imageSrc = location.imageSrc

    newMarker.addListener 'click', ->
      infoWindow = createInfoWindow(this)
      infoWindow.open(INTERACTIVE_MAP, this);

    MAP_MARKERS.push(newMarker)

markerPostitionSameAsLocation = (marker, location) ->
  marker.position.lat == location.latitude && marker.position.lng == location.longitude

loadScript =  ->
  if window.google
    initMap()
  else
    $.getScript "https://maps.googleapis.com/maps/api/js?key=#{API_KEY}", initMap


createInfoWindow = (marker) ->
  infowindow = new google.maps.InfoWindow
    content: """
<div class='map-info-window'>
  <h3 class='map-info-window-title'>
    <a href='#{marker.href}' class='map-info-window-link'>
      #{marker.title}
    </a>
  </h3>
  <a href='#{marker.href}' class='map-info-window-link'>
    <img src='#{marker.imageSrc}' class='map-info-window-image'/>
  </a>
  <p class='map-info-window-description'>#{marker.description}</p>
</div>
"""

export { init }
