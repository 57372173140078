init = ->
  $(".filter-block-toggle").on "change", onFilterBlockToggleChange

onFilterBlockToggleChange = (e) ->
  $filterBlockToggle = $(this)
  $filterBlock = $filterBlockToggle.closest(".filter-block")
  isChecked = $filterBlockToggle.is(":checked")
  $filterBlock.find(".filter-option-toggle").prop("checked", isChecked)

export { init }
