require("shared/base")

require("trix")
require("@rails/actiontext")

let cookieConsent = require("shared/cookie_consent");
let geolocation = require("shared/geolocation");
let maps = require("shared/maps");
let tfaForms = require("shared/tfa_forms");
let mainSearchForm = require("public/main_search_form");
let interactiveMaps = require("public/interactive_maps");
let filters = require("public/filters");
let lightbox = require("shared/lightbox");
let sliders = require("public/sliders");
let norightclick = require("shared/norightclick");
let collapsingNavbar = require("public/collapsing_navbar");
let ReCaptchable = require("public/re_captchable");
let visitable = require("public/visitable");

document.addEventListener("turbolinks:load", function() {
  mainSearchForm.init();
  cookieConsent.init();
  geolocation.init();
  maps.init();
  tfaForms.init();
  interactiveMaps.init();
  filters.init();
  lightbox.init();
  sliders.init();
  norightclick.init();
  collapsingNavbar.init();
  ReCaptchable.init();
  visitable.init();
});

$(window).resize(function() {
  sliders.init();
  collapsingNavbar.redraw();
});
