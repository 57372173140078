import { Flash } from "./flash"

FADEOUT_TIME = 3000

addNotice = (msg) ->
  addAlert('notice', msg)

addWarning = (msg) ->
  addAlert('warning', msg)

addError = (msg) ->
  addAlert('error', msg)

addAlert = (alertType, msg) ->
  flashHTML = new Flash(alertType, msg).toHTML()
  flashContainer = document.querySelector('div#flashes')
  if flashContainer
    flashContainer.appendChild(flashHTML)
  else
    console.error("Error: No flash container available")
  $flashHTML = $(flashHTML)
  setTimeout(->
    $flashHTML.fadeOut()
  , FADEOUT_TIME)
  return true

export { addError, addWarning, addNotice  }
