API_KEY = null

DEFAULT_LAT = 56.819683
DEFAULT_LON = -5.105320
DEFAULT_ZOOM = 12

init = ->
  mapObject = document.querySelector('.map')
  if mapObject == null || mapObject.dataset["googleApiKey"] == null
    return
  API_KEY = mapObject.dataset["googleApiKey"]
  loadScript()

markerChange = (marker) ->
  lat = marker.latLng.lat()
  lng = marker.latLng.lng()
  LatLng = new google.maps.LatLng(lat,lng)
  $("input[type=hidden][name$='[latitude]']").val(lat)
  $("input[type=hidden][name$='[longitude]']").val(lng)

initMap = ->
  if window.google
    mapObject = document.querySelector('.map')
    coords =
      lat: parseFloat(mapObject.dataset["latitude"])  || DEFAULT_LAT
      lng: parseFloat(mapObject.dataset["longitude"]) || DEFAULT_LON

  map = new google.maps.Map mapObject,
    zoom: DEFAULT_ZOOM
    center: coords
    zoomControl: true
    mapTypeControl: true
    scaleControl: true
    streetViewControl: false
    rotateControl: false
    fullscreenControl: false


  marker = new google.maps.Marker
    position: coords
    map: map
    draggable: true

  marker.addListener 'dragend', markerChange
  marker.addListener 'click', markerChange
  mapObject.addEventListener "map:marker:change_coords", (e) ->
    coord = new google.maps.LatLng(e.details.lat, e.details.lng)
    marker.setPosition(coord)
    map.setCenter(coord)

loadScript =  ->
  if window.google
    initMap()
  else
    $.getScript "https://maps.googleapis.com/maps/api/js?key=#{API_KEY}", initMap

export { init, loadScript }
