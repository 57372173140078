export class Flash

  constructor: (type, message) ->
    @type = type
    @message = message
    
  toHTML: ->
    flash = document.createElement("div")
    flash.addEventListener("click", @onClick)
    flash.className = "flash flash-#{@type}"
    flash.innerHTML = """
      <button type='button' class='close'>
        <span aria-hidden='true'>&times;</span>
      </button>
      #{@message}
    """    
    return flash

  onClick: (e) ->
    return unless e.target.parentNode.className.match(/close/)
    this.remove()